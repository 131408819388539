import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { StyledWrapper } from 'assets/global/styled';
import {
  StyledTestimonials,
  StyledTestimonialsSwiper,
  StyledTestimonialsContainer,
  StyledTestimonialsItem,
  StyledTestimonialsPartner,
  StyledTestimonialsContent,
  StyledTestimonialsGrid
} from './styled';
import MarkdownPreview from '@uiw/react-markdown-preview';
import Button from 'components/Form/Button';
import { Link } from 'gatsby';

SwiperCore.use([Navigation, Pagination]);

const Testimonials = (props) => {
  const { testimonials, page, ...otherProps } = props;
  const isThemeThreeColumn = otherProps.threeColumns;

  if (!isThemeThreeColumn) {
    return (
      <StyledTestimonials>
        <StyledWrapper>
          <StyledTestimonialsSwiper>
            <StyledTestimonialsContainer>
              <Swiper
                slidesPerView={1}
                loop="true"
                navigation
                pagination={{ clickable: true }}
              >
                {testimonials.map((testimonial, index) => {
                  const {
                    id,
                    logo,
                    author,
                    role,
                    description,
                    company,
                    showOnHome
                  } = testimonial;
                  if (showOnHome !== false) {
                    return (
                      <SwiperSlide key={id}>
                        <StyledTestimonialsItem>
                          {logo?.url && (
                            <StyledTestimonialsPartner>
                              <img
                                src={logo?.url}
                                width="auto"
                                height="72"
                                alt={logo?.alt || company || ''}
                              />
                            </StyledTestimonialsPartner>
                          )}
                          {description && author && (
                            <StyledTestimonialsContent>
                              <blockquote>
                                <p>{description}</p>
                              </blockquote>
                              {author && role && (
                                <p>
                                  <strong>{author} |</strong> {role}
                                </p>
                              )}
                            </StyledTestimonialsContent>
                          )}
                        </StyledTestimonialsItem>
                      </SwiperSlide>
                    );
                  }
                })}
              </Swiper>
            </StyledTestimonialsContainer>
          </StyledTestimonialsSwiper>
        </StyledWrapper>
      </StyledTestimonials>
    );
  } else if (
    typeof window !== 'undefined' &&
    window.screen.width > 980 &&
    isThemeThreeColumn
  ) {
    return (
      <StyledTestimonials>
        <StyledWrapper>
          <StyledTestimonialsSwiper>
            <StyledTestimonialsContainer>
              <Swiper
                slidesPerView={1}
                loop="true"
                navigation
                pagination={{ clickable: true }}
              >
                <SwiperSlide>
                  <StyledTestimonialsGrid>
                    {testimonials
                      .filter((value, index) => index <= 2)
                      .map((testimonial, index) => {
                        const {
                          id,
                          logo,
                          company,
                          showOnHome,
                          title1,
                          callToAction2
                        } = testimonial;
                        if (showOnHome !== false) {
                          return (
                            <StyledTestimonialsItem
                              key={id}
                              {...otherProps}
                            >
                              {logo?.url && (
                                <StyledTestimonialsPartner
                                  {...otherProps}
                                >
                                  <img
                                    src={logo?.url}
                                    width="auto"
                                    height="72"
                                    alt={logo?.alt || company || ''}
                                  />
                                </StyledTestimonialsPartner>
                              )}
                              <StyledTestimonialsContent
                                {...otherProps}
                              >
                                <MarkdownPreview source={title1} />
                                <Button
                                  style={{ justifyContent: 'center' }}
                                  to={callToAction2}
                                  as={Link}
                                  type="button"
                                  callToAction={true}
                                  withIcon={true}
                                >
                                  {page.content5Title}
                                </Button>
                              </StyledTestimonialsContent>
                            </StyledTestimonialsItem>
                          );
                        }
                      })}
                  </StyledTestimonialsGrid>
                </SwiperSlide>
                <SwiperSlide>
                  <StyledTestimonialsGrid>
                    {testimonials
                      .filter(
                        (value, index) => index > 2 && index <= 6
                      )
                      .map((testimonial, index) => {
                        const {
                          id,
                          logo,
                          company,
                          showOnHome,
                          title1,
                          callToAction2
                        } = testimonial;
                        if (showOnHome !== false) {
                          return (
                            <StyledTestimonialsItem
                              key={id}
                              {...otherProps}
                            >
                              {logo?.url && (
                                <StyledTestimonialsPartner
                                  {...otherProps}
                                >
                                  <img
                                    src={logo?.url}
                                    width="auto"
                                    height="72"
                                    alt={logo?.alt || company || ''}
                                  />
                                </StyledTestimonialsPartner>
                              )}
                              <StyledTestimonialsContent
                                {...otherProps}
                              >
                                <MarkdownPreview source={title1} />
                                <Button
                                  style={{ justifyContent: 'center' }}
                                  to={callToAction2}
                                  as={Link}
                                  type="button"
                                  callToAction={true}
                                  withIcon={true}
                                >
                                  {page.content5Title}
                                </Button>
                              </StyledTestimonialsContent>
                            </StyledTestimonialsItem>
                          );
                        }
                      })}
                  </StyledTestimonialsGrid>
                </SwiperSlide>
              </Swiper>
            </StyledTestimonialsContainer>
          </StyledTestimonialsSwiper>
        </StyledWrapper>
      </StyledTestimonials>
    );
  } else {
    return (
      <StyledTestimonials>
        <StyledWrapper>
          <StyledTestimonialsSwiper>
            <StyledTestimonialsContainer>
              <Swiper
                slidesPerView={1}
                loop="true"
                navigation
                pagination={{ clickable: true }}
              >
                {testimonials.map((testimonial, index) => {
                  const {
                    id,
                    logo,
                    company,
                    showOnHome,
                    title1,
                    callToAction2
                  } = testimonial;
                  if (showOnHome !== false) {
                    return (
                      <SwiperSlide>
                        <StyledTestimonialsGrid>
                          <StyledTestimonialsItem key={id}>
                            {logo?.url && (
                              <StyledTestimonialsPartner
                                {...otherProps}
                              >
                                <img
                                  src={logo?.url}
                                  width="auto"
                                  height="72"
                                  alt={logo?.alt || company || ''}
                                />
                              </StyledTestimonialsPartner>
                            )}
                            <StyledTestimonialsContent
                              {...otherProps}
                            >
                              <MarkdownPreview source={title1} />
                              <Button
                                style={{ justifyContent: 'center' }}
                                to={callToAction2}
                                as={Link}
                                type="button"
                                callToAction={true}
                                withIcon={true}
                              >
                                {page.content5Title}
                              </Button>
                            </StyledTestimonialsContent>
                          </StyledTestimonialsItem>
                        </StyledTestimonialsGrid>
                      </SwiperSlide>
                    );
                  }
                })}
              </Swiper>
            </StyledTestimonialsContainer>
          </StyledTestimonialsSwiper>
        </StyledWrapper>
      </StyledTestimonials>
    );
  }
};

export default Testimonials;
