import fonts from 'assets/global/fonts';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  background-color: #fafafa;
`;

export const StyledContent = styled.div`
  display: flex;
  padding: 50px 0px 50px 0px;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 100px;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    flex-wrap: wrap;

    iframe {
      width: 100%;
      height: 200px;
    }
  }
`;

export const StyledItem = styled.div`
  width: 100%;

  p {
    font-family: ${fonts.secondary};
    font-style: normal;
    font-size: 42px;
    line-height: 120%;
  }

  @media screen and (max-width: 980px) {
    p {
      font-family: 'Poppins';
      font-style: normal;
      font-size: 18px;
      line-height: 151%;
    }
  }
`;

export const StyledLineSlice = styled.div`
  display: block;
  margin-bottom: 50px;
  border-bottom: solid 1px red;
  width: 400px;

  @media screen and (max-width: 980px) {
    width: 250px;
  }
`;
