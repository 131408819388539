import styled, { css } from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledHero = styled.section`
  display: block;
  width: 100%;
  padding: 30px 0;
  margin: 30px 0;

  @media screen and (max-width: 980px) {
    height: auto;
  }
`;

export const StyledHeroSwiper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;

  ${(props) =>
    props.layout === 'reverse'
      ? css`
          flex-direction: row-reverse;
        `
      : css`
          flex-direction: row;
        `}

  @media screen and (max-width: 980px) {
    align-content: center;
  }
`;

export const StyledHeroContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;

  ${(props) =>
    props.layout === 'reverse'
      ? css`
          flex-direction: row-reverse;
        `
      : css`
          flex-direction: row;
        `}

  @media screen and (max-width: 980px) {
    align-content: center;
  }

  form {
    width: 48.5%;
    margin-top: 50px;
    text-align: right;

    @media screen and (max-width: 980px) {
      width: 100%;
    }
  }
`;

export const StyledHeroContent = styled.div`
  position: relative;
  z-index: 5;
  display: block;
  width: 48.5%;

  p,
  ul {
    font-size: 18px;
    font-weight: 300;
    color: ${colors.gray300};
    line-height: 1.8;
    font-family: ${fonts.secondary};

    strong {
      font-weight: 700;
    }

    a:link {
      text-decoration: none;
    }

    a:visited {
      text-decoration: none;
    }

    a:hover {
      text-decoration: none;
    }

    a:active {
      text-decoration: none;
    }
  }

  a {
    position: relative;
    display: inline-block;
    margin-top: 20px;
    font-size: 16px;
    color: ${colors.red400};
    text-decoration: none;

    &:before,
    &:after {
      position: absolute;
      bottom: 0;
      width: 0;
      height: 1px;
      background-color: ${colors.red400};
      transition: all 0.3s linear;
      content: '';
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    &:hover {
      &:before,
      &:after {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 980px) {
    width: 100%;
  }

  @media screen and (max-width: 520px) {
    p {
      font-size: 16px;
    }
  }
`;

export const StyledHeroContentService = styled.div`
  position: relative;
  z-index: 5;
  display: block;
  width: 48.5%;

  p {
    font-size: 18px;
    font-weight: 300;
    color: ${colors.gray300};
    line-height: 1.8;
    font-family: ${fonts.secondary};
    padding-left: 30px;

    strong {
      font-weight: 700;
    }

    a:link {
      text-decoration: none;
    }

    a:visited {
      text-decoration: none;
    }

    a:hover {
      text-decoration: none;
    }

    a:active {
      text-decoration: none;
    }
  }

  ul {
    margin: 0;
    padding-left: 30px;
    list-style-type: none;

    li {
      margin-top: 10px;
      font-size: 16px;
      font-family: ${fonts.secondary};
      color: ${colors.gray700};

      &:first-child {
        margin-top: 0;
      }
    }
  }

  a {
    position: relative;
    display: inline-block;
    margin-top: 20px;
    font-size: 16px;
    color: ${colors.red400};
    text-decoration: none;
    margin-left: 30px;

    &:before,
    &:after {
      position: absolute;
      bottom: 0;
      width: 0;
      height: 1px;
      background-color: ${colors.red400};
      transition: all 0.3s linear;
      content: '';
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    &:hover {
      &:before,
      &:after {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 980px) {
    width: 100%;
    p,
    ul {
      padding-left: 0;
    }
    a {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 520px) {
    p {
      font-size: 16px;
    }
  }
`;

export const StyledHeroSubtitle = styled.h3`
  position: relative;
  margin: 0 0 1rem 0;
  padding: 20px 0;
  font-family: ${fonts.primary};
  font-size: 24px;
  font-weight: 500;
  color: ${colors.gray300};

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 2px;
    background-color: ${colors.gray900};
    content: '';
  }

  @media screen and (max-width: 980px) {
    font-size: 12px;
    font-weight: 400;
  }
`;

export const StyledHeroMainTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 40px;
  font-family: ${fonts.primary};
  font-size: 52px;
  color: ${colors.gray300};
  line-height: 1.1;

  @media screen and (max-width: 980px) {
    font-size: 14px;
    font-weight: 600;
  }
`;

export const StyledHeroTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 40px;
  font-family: ${fonts.primary};
  font-size: 52px;
  color: ${colors.gray300};
  line-height: 1.1;

  @media screen and (max-width: 980px) {
    font-size: 14px;
    font-weight: 600;
  }
`;

export const StyledHeroImage = styled.div`
  position: relative;
  z-index: 4;
  display: block;
  width: 48.5%;

  img {
    display: block;
    max-width: 100%;
    height: auto;
    padding-left: 30px;

    @media screen and (max-width: 980px) {
      padding-left: 0;
    }
  }

  @media screen and (max-width: 980px) {
    display: none;
  }
`;
