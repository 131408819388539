import React from 'react';
import Helmet from 'react-helmet';
import ContactUs from 'components/ContactUs';
import Testimonials from 'templates/Home/Testimonials';
import PartnersSwiper from 'components/PartnersSwiper';
import { StyledSection } from 'templates/Services/styled';
import { StyledSubtitle, StyledWrapper } from 'assets/global/styled';
import CaseSuccess from 'components/CaseSuccess';

const Contact = ({
  page,
  pageContext,
  awards,
  customers,
  testimonials
}) => {
  const {
    contactForm,
    locale,
    content2Title,
    content3Title,
    content4Title,
    content2,
    content3
  } = page;
  const { baseURL } = pageContext;
  return (
    <React.Fragment>
      <ContactUs
        isContact={true}
        page={page}
        contactForm={contactForm}
        baseURL={baseURL}
        lang={locale}
        awards={awards}
        dark
      />

      <StyledSection>
        <StyledWrapper>
          <StyledSubtitle>{content2Title}</StyledSubtitle>
        </StyledWrapper>
        <PartnersSwiper page={page} customers={customers} />
      </StyledSection>

      <StyledSection>
        <StyledWrapper>
          <StyledSubtitle>{content3Title}</StyledSubtitle>
        </StyledWrapper>
        <Testimonials testimonials={testimonials} />
      </StyledSection>

      <StyledSection>
        <CaseSuccess contentTitle={content2} content={content3} />
      </StyledSection>

      <StyledSection>
        <StyledWrapper>
          <StyledSubtitle>{content4Title}</StyledSubtitle>
        </StyledWrapper>
        <Testimonials
          testimonials={testimonials}
          page={page}
          threeColumns
        />
      </StyledSection>

      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context":"https://schema.org",
              "@type":"Organization",
              "name":"ateliware",
              "legalName":"ateliware",
              "url":"http://ateliware.com/br/contact",
              "logo":"https://media.graphassets.com/gJsd71c5SGh1RyV8tIrV",
              "address":{
                "@type":"PostalAddress",
                "streetAddress":"Rua Pasteur, 463",
                "addressLocality":"Curitiba",
                "addressRegion":"Paraná",
                "postalCode":"80250-080",
                "addressCountry":"Brasil"
              },
              "contactPoint":{
                "@type":"ContactPoint",
                "contactType":"Contato",
                "telephone":"[+55 41 3010-2275]",
                "email":"hello@ateliware.com"
              },
              "sameAs":[
                "https://github.com/ateliware",
                "https://www.linkedin.com/company/ateliware/",
                "https://twitter.com/ateliware",
                "https://www.youtube.com/channel/UCxPjijyar2emdKLPpOrugOw",
                "https://www.meetup.com/ateliware/",
                "https://goo.gl/maps/gLPc35mF3js7rLnL8"
              ]
            }
          `}
        </script>
      </Helmet>
    </React.Fragment>
  );
};

export default Contact;
