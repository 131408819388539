import styled, { css } from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledTestimonials = styled.section`
  display: block;
  width: 100%;
  padding: 30px 0;
`;

export const StyledTestimonialsSwiper = styled.div`
  display: block;
`;

export const StyledTestimonialsContainer = styled.div`
  display: block;
`;

export const StyledTestimonialsGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const StyledTestimonialsItem = styled.div`
  display: block;
  max-width: 60%;
  margin: 0 auto;
  ${(props) =>
    props.threeColumns ? 'padding: 0px 50px 0px 50px;' : null}
  text-align: center;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const StyledTestimonialsPartner = styled.div`
  margin-top: 2rem;
  text-align: center;
  ${(props) => (props.threeColumns ? 'margin-bottom: 50px;' : null)}
`;

export const StyledTestimonialsContent = styled.div`
  display: block;

  blockquote {
    margin: 0;
    padding: 0;
    font-family: ${fonts.secondary};
    font-weight: 100;
    color: ${colors.gray300};
    line-height: 1.8;
  }

  p {
    font-weight: 300;
    ${(props) => (props.threeColumns ? 'font-size: 14px;' : null)}

    strong {
      font-weight: 700;
    }
  }

  a {
    ${(props) =>
      props.threeColumns &&
      css`
        margin-top: 50px;
        margin-bottom: 50px;
      `}
  }
`;
