import React from 'react';
import SEO from 'components/SEO';
import { graphql } from 'gatsby';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Contact from 'templates/Contact';

const ContactPage = ({
  data: {
    ateliware: {
      page,
      headerMenu,
      footerMenu,
      awards,
      customers,
      testimonials
    }
  },
  pageContext
}) => {
  const { seo } = page;
  const { locale } = pageContext;
  return (
    <React.Fragment>
      <SEO
        meta_title={
          locale == 'pt'
            ? seo?.metaTitle
            : 'software and digital product development'
        }
        meta_description={
          locale == 'pt'
            ? seo?.metaDescription
            : 'More than a supplier that understands technology, here at ateliware we transform and simplify business. We are a software studio that develops handmade products and solutions.'
        }
        og_title={
          locale == 'pt'
            ? seo?.ogTitle
            : 'software and digital product development'
        }
        og_description={
          locale == 'pt'
            ? seo?.ogDescription
            : 'More than a supplier that understands technology, here at ateliware we transform and simplify business. We are a software studio that develops handmade products and solutions.'
        }
        og_type={seo?.ogType}
        og_image={seo?.ogImage?.url}
        lang={locale}
      />
      <Header menu={headerMenu} pageContext={pageContext} />
      <Contact
        page={page}
        pageContext={pageContext}
        awards={awards}
        customers={customers}
        testimonials={testimonials}
      />
      <Footer
        footerMenu={footerMenu}
        pageContext={pageContext}
        privatePolicyTitle={page.contactForm.privacyPolicyTitle}
      />
    </React.Fragment>
  );
};

export const contactQuery = graphql`
  query ContactPageQuery($idPage: ID!, $locale: [GCMS_Locale!]!) {
    ateliware {
      page(where: { id: $idPage }, locales: $locale) {
        seo {
          metaTitle
          metaDescription
          ogTitle
          ogDescription
          ogType
          ogImage {
            url
          }
        }
        locale
        slug
        title
        content1Image {
          alt
          url
        }
        description
        contentTitle
        content
        contentSubtitle
        content1Subtitle
        content2Subtitle
        content1Title
        content1
        content2
        content3
        content2Title
        content3Title
        content4Title
        content5Title
        contactForm {
          title
          content
          nameField
          surnameField
          companyField
          emailField
          phoneField
          serviceOptionDefault
          serviceOption1
          serviceOption2
          serviceOption3
          serviceOption4
          aboutYouField
          budgetDefault
          budgetOption1
          budgetOption2
          budgetOption3
          sendButtonText
          formErrorMessage
          privacyPolicyTitle
          privacyPolicyText
          thankYouTitle
          thankYouContent
          backToSiteText
          toBlogText
        }
      }
      testimonials(locales: $locale) {
        id
        logo(locales: pt) {
          handle
          width
          height
          alt
          url
          fileName
          title
        }
        author
        role
        description
        company
        showOnHome
        title1
        callToAction2
      }
      headerMenu: menu(where: { title: "header" }, locales: $locale) {
        locale
        callToAction
        menuItems {
          title
          slug
          locale
          goTo
          externalLink
          subMenuItems {
            title
            slug
            locale
            goTo
            externalLink
          }
        }
      }
      footerMenu: menu(where: { title: "footer" }, locales: $locale) {
        locale
        callToAction
        menuItems {
          title
          slug
          locale
          goTo
          externalLink
        }
      }
      awards(where: { showOnPage_contains_some: Contact }) {
        image(locales: pt) {
          alt
          url
        }
      }
      customers(where: { showOnPage: Home }) {
        id
        name
        website
        logo {
          alt
          title
          url
        }
      }
    }
  }
`;

export default ContactPage;
