import React from 'react';
import { StyledWrapper } from 'assets/global/styled';
import {
  StyledContainer,
  StyledContent,
  StyledItem,
  StyledLineSlice
} from './styled';
import MarkdownPreview from '@uiw/react-markdown-preview';

const CaseSuccess = (props) => {
  const { contentTitle, content } = props;
  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledContent>
          <StyledItem>
            <StyledLineSlice />
            <MarkdownPreview source={contentTitle} />
          </StyledItem>
          <StyledItem>
            <MarkdownPreview source={content} />
          </StyledItem>
        </StyledContent>
      </StyledWrapper>
    </StyledContainer>
  );
};

export default CaseSuccess;
