import styled from 'styled-components';

export const StyledCreations = styled.section`
  display: block;
  padding: 30px 0;
  margin: 30px 0;

  @media screen and (max-width: 980px) {
    padding: 15px 0px;
    margin: 15px 0px;
  }
`;

export const StyledCreationsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }

  .swiper-slide {
    align-self: center;
  }
`;

export const StyledCreationsItem = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 100px 50px 100px;

  img {
    width: 20%;
    max-width: 20%;
    height: auto;
    align-self: center;
  }

  @media screen and (max-width: 768px) {
    margin: 0 0 50px 0;
  }

  @media screen and (min-width: 981px) {
    margin: 0 100px 0px 100px;
  }
`;
