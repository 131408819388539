import React, { useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { graphql, useStaticQuery } from 'gatsby';
import { StyledWrapper } from 'assets/global/styled';
import {
  StyledCreations,
  StyledCreationsContainer,
  StyledCreationsItem
} from './styled';
import { StyledHeroSubtitle } from 'templates/Home/Hero/styled';

const CreationsSwiper = ({ page, customers, wrapperStyle }) => {
  const { titleCreation } = page;

  const group = (array, n) =>
    [...Array(Math.ceil(array.length / n))].map((el, i) =>
      array.slice(i * n, (i + 1) * n)
    );

  const customersGrouped = useMemo(
    () =>
      group(
        customers.filter((c) => !!c.logo.url),
        4
      ),
    [customers]
  );

  return (
    <React.Fragment>
      {customers.length > 0 && (
        <StyledCreations style={wrapperStyle}>
          <StyledWrapper>
            <StyledCreationsContainer>
              {titleCreation && (
                <StyledHeroSubtitle>
                  {titleCreation}
                </StyledHeroSubtitle>
              )}

              <Swiper
                slidesPerView={1}
                loop="true"
                navigation
                pagination={{ clickable: true }}
              >
                {customersGrouped.map((customersGroup, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <StyledCreationsItem>
                        {customersGroup.map((customer, index) => {
                          const { name, logo, website } = customer;

                          return website ? (
                            <a
                              href={website}
                              title={name}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={logo?.url}
                                width="auto"
                                alt={name}
                              />
                            </a>
                          ) : (
                            <img
                              src={logo?.url}
                              width="auto"
                              alt={name}
                              key={index}
                            />
                          );
                        })}
                      </StyledCreationsItem>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </StyledCreationsContainer>
          </StyledWrapper>
        </StyledCreations>
      )}
    </React.Fragment>
  );
};

export default CreationsSwiper;
